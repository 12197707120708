import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from 'views/Home';
import Payment from 'views/Payment';
import Product from 'views/Product';
import ProductDetail from 'views/ProductDetail';
import Summary from 'views/Summary';

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product" element={<Product />} />
        <Route path="/product/detail" element={<ProductDetail />} />
        <Route path="/summary" element={<Summary />} />
        <Route path="/payment" element={<Payment />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
