import React from 'react';
import { FaChevronLeft } from 'react-icons/fa';
import styles from './styles.module.scss';
import { Navbar, Row, Button, Spacing, Steps } from '..';

function Layout({
  children,
  verticalCentered = false,
  navbar = false,
  steps,
  goBackButton = false,
  onClick,
  navBarButtons = false
}: any) {
  return (
    <>
      {navbar && <Navbar navigationButtons={navBarButtons} />}
      <div className={styles.container}>
        {steps && (
          <>
            <Spacing size="medium" />
            <Row position="centered">
              <Steps steps={steps} />
            </Row>
          </>
        )}
        {goBackButton && (
          <>
            <Spacing size="medium" />
            <Row position="start">
              <Button
                width="188px"
                text="Voltar para o Painel"
                appearance="ghost"
                iconPosition="left"
                onClick={onClick}
              >
                <FaChevronLeft />
              </Button>
            </Row>
          </>
        )}
        <Spacing size="small" />
        <div
          className={styles.content}
          style={{ justifyContent: verticalCentered ? 'center' : 'start' }}
        >
          {children}
        </div>
        <Spacing size="x-large" />
      </div>
    </>
  );
}

export default Layout;
