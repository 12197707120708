import React from 'react';
import { Button, Spacing } from '..';
import styles from './styles.module.scss';

export type Props = {
  labelLeft?: string;
  appearanceLeft?: 'primary' | 'complementary' | 'ghost' | 'disabled';
  actionLeft?: any;
  labelRight?: string;
  appearanceRight?: 'primary' | 'complementary' | 'ghost' | 'disabled';
  actionRight?: any;
};

function BottomNavigation({
  labelLeft,
  appearanceLeft,
  actionLeft,
  labelRight,
  appearanceRight,
  actionRight
}: Props) {
  return (
    <div className={styles.container}>
      {labelLeft && (
        <div className={styles.leftButton}>
          <Button appearance={appearanceLeft} text={labelLeft} onClick={actionLeft} />
        </div>
      )}
      {labelRight && (
        <div className={styles.rightButton}>
          <Button appearance={appearanceRight} text={labelRight} onClick={actionRight} />
          <Spacing size="large" />
        </div>
      )}
    </div>
  );
}

export default BottomNavigation;
