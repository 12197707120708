/* eslint-disable react/no-danger */
import {
  Button,
  Column,
  Description,
  Input,
  Navbar,
  Row,
  Select,
  Spacing,
  Text,
  Undertitle,
  Card
} from 'components';
import React, { useEffect, useState } from 'react';
import { FaMapMarkerAlt, FaPlus, FaSearch } from 'react-icons/fa';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { maskMoney } from 'utils/mask';
import styles from './styles.module.scss';
import target from '../../assets/icons/location-target-icon.png';
import cart from '../../assets/icons/cart-icon.png';
import logoPrimary from '../../assets/logo-primary.png';

import checkupComplete from '../../assets/checkup-complete-2.png';
import logo from '../../assets/logos/logo-branca.png';

export default function Product() {
  const navigate = useNavigate();
  const [search, setSearch] = useState();
  const [city, setCity] = useState('Porto Alegre');
  const [location, setLocation] = useState({
    city: '',
    county: ''
  });

  function getPackages() {
    axios
      .get(`https://saude.startaideia.dev/api/coverage-areas`, {
        headers: {
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then((response) => console.log('packages: ', response.data));
  }

  const packages = {
    name: 'Check-up Completo',
    code: '123456',
    description:
      'O Check-up Completo oferece uma gama de exames e procedimentos que visam a prevenção primária e secundária de patologias relacionada a saúde. São 5 procedimentos para manter seus exames de rotina em dia.',

    indication:
      'O Check-up Completo é recomendado para as qual pessoa a partir de 18 anos de idade, visando ao diagnóstico precoce e prevenção de patologias',
    guideline: 'Opção implementada corretamente mas alterar objeto para uma lista',
    exams: [
      {
        name: 'Consulta Clínica'
      },
      {
        name: 'Exames Laboratoriais'
      }
    ],
    value: 0
  };

  useEffect(() => {}, []);

  const locationData = [
    {
      title: 'Porto Alegre',
      value: 'Porto Alegre'
    },
    {
      title: 'São Leopoldo',
      value: 'São Leopoldo'
    },
    {
      title: 'Canoas',
      value: 'Canoa'
    },
    {
      title: 'Novo Hamburgo',
      value: 'Novo Hamburgo'
    }
  ];

  return (
    <div className={styles.container}>
      <Navbar navigationButtons />
      <Spacing size="xxx-large" />

      <div className={styles.searchField}>
        <Column>
          <div className={styles.location}>
            <img src={target} draggable="false" alt="target" />
            <Select
              name="city"
              label=""
              width="25%"
              appearance="ghost"
              data={locationData}
              value={city}
              onclick={(data) => setCity(data)}
            />
          </div>
        </Column>
        <Column position="center">
          <Input
            name="search"
            type="text"
            width="100%"
            icon={<FaSearch />}
            placeholder="O que você precisa hoje?"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </Column>
        <div className={styles.icons}>
          <Row>
            <div className={styles.cart}>
              <img src={cart} className={styles.icon} alt="cart" />
              <div className={styles.notification}>2</div>
            </div>
          </Row>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.contentColumn}>
          <h1>{packages.name}</h1>
          <Description appearance="grey">Cód: {packages.code}</Description>
          <Spacing size="xx-small" />
          <p className={styles.text} dangerouslySetInnerHTML={{ __html: packages.description }} />
          <Spacing size="small" />
          <Undertitle bolder={true} appearance="grey">
            Para quem é indicado e onde devo fazer?
          </Undertitle>
          <p className={styles.text} dangerouslySetInnerHTML={{ __html: packages.indication }} />
          <Spacing size="small" />
          <Undertitle bolder={true} appearance="grey">
            Orientações gerais
          </Undertitle>
          <div dangerouslySetInnerHTML={{ __html: packages.guideline }} />
          <Spacing size="small" />
          <Undertitle bolder={true} appearance="grey">
            Onde realizar o exame?
          </Undertitle>
          <div className={styles.partners}>
            <img src={logoPrimary} alt="img" />
          </div>
          <p className={styles.text}>
            A compra do Pacote Check-up Completo garante a você a aquisição do serviço, que deverá
            ser agendando e, posteriormente, feito presencialmente em uma das clínicas credenciadas
            ao Sistema Saúde Mãe de Deus.
          </p>
          <p className={styles.text}>Confira passo a passo:</p>
          <ol>
            <li>
              Após clicar no botão Comprar, informe seu CPF, e-mail e telefone para ser encaminhado
              para a página de pagamento.
            </li>
            <li>Em seguida, faça autenticação com o código enviado para seu e-mail e via SMS.</li>
            <li>Escolha entre as opções de pagamento cartão de crédito, PIX ou boleto.</li>
            <li>
              Após receber a confirmação da compra você receberá um e-mail com todas as instruções
              para agendar a sua consulta.
            </li>
            <li>Utilize o código que será enviado por e-mail para agendar a sua consulta.</li>
          </ol>
        </div>
        <div className={`${styles.contentColumn} ${styles.resume}`}>
          <Card appearance="ghost" width="95%">
            <img src={checkupComplete} className={styles.banner} alt="banner" />
            <Spacing size="small" />
            <Row>
              <Column>
                <h2 className={styles.title}>{packages.name}</h2>
              </Column>
            </Row>
            <Spacing size="x-small" />
            <Text appearance="grey">Cód: {packages.code}</Text>
            <Spacing size="xx-large" />
            <ul className={styles.productDetails}>
              {packages.exams.map((exam) => (
                <li key={exam.name}> {exam.name}</li>
              ))}
            </ul>
            <Spacing size="xx-large" />
            <h1>R$ {maskMoney(Number(packages.value))}</h1>
            <Undertitle position="start" bolder={true}>
              ou 2x no cartão sem juros
            </Undertitle>
            <Spacing size="xx-large" />
            <p className={styles.location}>
              <span>
                <FaMapMarkerAlt />
              </span>
              Este preço é referente a região de
              <Select
                name="city"
                label=""
                width="25%"
                appearance="ghost"
                data={[
                  {
                    title: 'Porto Alegre',
                    value: 'Porto Alegre'
                  },
                  {
                    title: 'São Leopoldo',
                    value: 'São Leopoldo'
                  },
                  {
                    title: 'Canoas',
                    value: 'Canoa'
                  },
                  {
                    title: 'Novo Hamburgo',
                    value: 'Novo Hamburgo'
                  }
                ]}
                value={city}
                onclick={(data) => setCity(data)}
              />
            </p>
            <Spacing size="xx-large" />
            <Button
              text="Comprar agora"
              width="372px"
              appearance="primary"
              onClick={() => navigate('/summary')}
            />
            <Spacing size="large" />
            <Button
              text="Adicionar ao carrinho"
              width="372px"
              appearance="ghost"
              iconPosition="left"
              onClick={() => console.log('add_cart')}
            >
              <FaPlus />
            </Button>
          </Card>
        </div>
      </div>

      <div className={styles.footer}>
        <div className={styles.footerContent}>
          <div className={`${styles.footerColumn} ${styles.centered}`}>
            <img src={logo} className={styles.logo} draggable="false" alt="logo" />
            <Description appearance="white">
              Trazemos um novo olhar para sua saúde. Soluções rápidas e eficientes, mais saúde,
              resolutividade e menos gastos para todos.
            </Description>
          </div>
          <div className={`${styles.footerColumn} ${styles.end}`}>
            <div>
              <p>Planos e Serviços</p>
              <Spacing size="medium" />
              <p>Procedimentos</p>
              <Spacing size="medium" />
              <p>Contato</p>
              <Spacing size="medium" />
              <p>Perguntas Frequentes</p>
            </div>
          </div>
          <div className={`${styles.footerColumn} ${styles.end}`}>
            <div>
              <p>Planos e Serviços</p>
              <Spacing size="medium" />
              <p>Procedimentos</p>
              <Spacing size="medium" />
              <p>Contato</p>
              <Spacing size="medium" />
              <p>Perguntas Frequentes</p>
            </div>
          </div>
        </div>
        <Spacing size="xx-large" />
        <Row>
          <Description appearance="white">
            Todos os direitos reservados - 2023. Desenvolvido por Starta Ideia
          </Description>
        </Row>
      </div>
    </div>
  );
}
