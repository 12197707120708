import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { maskMoney } from 'utils/mask';
import {
  Layout,
  Title,
  Text,
  Spacing,
  Row,
  Divider,
  BottomNavigation,
  Modal,
  Input
} from 'components';
import Product from './components/Product';

import checkupComplete from '../../assets/checkup-complete-2.png';

import styles from './styles.module.scss';

function Summary() {
  const navigate = useNavigate();
  const [totalValue, setTotalValue] = useState(0);
  const [products, setProducts] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [coupon, setCoupon] = useState();
  const mock = [
    {
      id: 1,
      image: checkupComplete,
      title: 'Check-up Completo',
      description:
        'Disponibilizamos em nossos pacotes de check-up um conjunto de exames e consultas de acordo com sua necessidade',
      productValue: 349.99,
      totalValue: 349.99
    },
    {
      id: 2,
      image: checkupComplete,
      title: 'Check-up Completo',
      description:
        'Disponibilizamos em nossos pacotes de check-up um conjunto de exames e consultas de acordo com sua necessidade',
      productValue: 349.99,
      totalValue: 349.99
    },
    {
      id: 3,
      image: checkupComplete,
      title: 'Check-up Completo',
      description:
        'Disponibilizamos em nossos pacotes de check-up um conjunto de exames e consultas de acordo com sua necessidade',
      productValue: 349.99,
      totalValue: 349.99
    }
  ];

  useEffect(() => {
    let amount: any = 0;
    mock.map((elem) => (amount += elem.productValue));
    setProducts(mock);
    setTotalValue(amount);
  }, []);

  function onClickDelete(id: number) {
    const index = products.findIndex((element) => element.id === id);
    products.splice(index, 1);
    setProducts([...products]);

    let amount: any = 0;
    products.map((elem) => (amount += elem.totalValue));
    setTotalValue(amount);
  }

  function addProduct(id: number, value: any) {
    const index = products.findIndex((element) => element.id === id);
    products[index].totalValue = value;
    setProducts(products);

    let amount: any = 0;
    products.map((elem) => (amount += elem.totalValue));
    setTotalValue(amount);
  }

  function subsProduct(id: number, value: any) {
    const index = products.findIndex((element) => element.id === id);
    products[index].totalValue = value;
    setProducts(products);

    let amount: any = 0;
    products.map((elem) => (amount += elem.totalValue));
    setTotalValue(amount);
  }

  return (
    <Layout navbar={true}>
      <Modal
        isOpen={modalIsOpen}
        title="Insira um Cupom"
        description="Cada produto pode ter apenas um cupom aplicado."
        onClickClose={() => setModalIsOpen(false)}
      >
        <Spacing size="large" />
        <Input
          name="coupon"
          type="text"
          width="100%"
          placeholder="Inserir Código do cupom"
          value={coupon}
          onChange={(event) => {
            setCoupon(event.target.value);
          }}
        />
        <Spacing size="large" />
        <BottomNavigation
          // width="74px"
          labelLeft="Cancelar"
          appearanceLeft="ghost"
          actionLeft={() => setModalIsOpen(false)}
          labelRight="Aplicar"
          appearanceRight="primary"
          actionRight={() => setModalIsOpen(false)}
        />
      </Modal>
      <Spacing size="xx-large" />
      <div className={styles.container}>
        <Title bolder={true} appearance="primary">
          Resumo de sua compra
        </Title>
        <Spacing size="x-small" />
        <Text>Verifique os itens selecionados, antes de finalizar a compra</Text>
        <Spacing size="x-large" />
        {products.map((product) => (
          <>
            <Spacing size="large" />
            <Product
              image={product.image}
              title={product.title}
              description={product.description}
              productValue={product.productValue}
              totalValue={product.totalValue}
              onClickDelete={() => onClickDelete(product.id)}
              addProduct={(event) => addProduct(product.id, event)}
              subsProduct={(event) => subsProduct(product.id, event)}
            />
          </>
        ))}
        <Spacing size="xxx-large" />
        <Row position="start">
          <p className={styles.ticket} onClick={() => setModalIsOpen(true)}>
            <img alt="codigocupom" /> Inserir código do cupom
          </p>
        </Row>
        <Spacing size="x-small" />
        <Divider />
        <Spacing size="small" />
        <div className={styles.value}>
          <h1>Total</h1>
          <h1>R$ {maskMoney(totalValue)}</h1>
        </div>
      </div>

      <Spacing size="xxx-large" />

      <BottomNavigation
        // width="148px"
        labelLeft="Voltar"
        appearanceLeft="ghost"
        actionLeft={() => navigate('/autenticacao')}
        labelRight="Finalizar Compra"
        appearanceRight="primary"
        actionRight={() => navigate('/payment')}
      />
    </Layout>
  );
}

export default Summary;
