import React from 'react';
import { Spacing, Undertitle } from 'components';
import { FaMinus, FaPlus } from 'react-icons/fa';
import styles from './styles.module.scss';

function Amount({ amount, addAmount, subsAmount }) {
  return (
    <div className={styles.container}>
      <p onClick={subsAmount}>
        <FaMinus />
      </p>
      <Spacing size="x-small" />
      <Undertitle bolder={true} appearance="primary">
        {amount}
      </Undertitle>
      <Spacing size="x-small" />
      <p onClick={addAmount}>
        <FaPlus />
      </p>
    </div>
  );
}

export default Amount;
