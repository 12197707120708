import React from 'react';
import { maskCreditCard, maskValidDate } from 'utils/mask';
import { Card, Column, Description, Row, Title, Undertitle, Input, Spacing, Button } from '..';
import styles from './styles.module.scss';

export function CardItem({ data }) {
  return (
    <div className={styles.creditCard}>
      <Row position="end">
        <div className={styles.creditCardChip} />
      </Row>
      <div className={styles.creditCardInfos}>
        <Row position="start">
          <Title appearance="white" bolder={true}>
            {data.number !== '' ? maskCreditCard(data.number) : '0000 0000 0000 0000'}
          </Title>
        </Row>
        <Spacing size="small" />
        <Row>
          <Column>
            <Description appearance="white">
              {data.name !== '' ? data.name : 'Nome no cartão'}
            </Description>
          </Column>
          <Column>
            <Description appearance="white">
              {data.validDate !== '' ? maskValidDate(data.validDate) : 'Mês / Ano'}
            </Description>
          </Column>
          <Column>
            <Description appearance="white" textAlign="left">
              {data.code !== '' ? data.code : 'CVV'}
            </Description>
          </Column>
        </Row>
      </div>
    </div>
  );
}

function CreditCard({ form, setForm, onClick }) {
  return (
    <Card appearance="ghost" width="100%">
      <Column position="start">
        <Undertitle bolder={true} appearance="grey">
          Cartão de Crédito
        </Undertitle>
        <Spacing size="xxx-small" />
        <Description appearance="grey">Escaneie este código para pagar</Description>
      </Column>
      <Spacing size="large" />
      <Row>
        <Column>
          <CardItem data={form} />
        </Column>
        <Spacing size="medium" />
        <Column>
          <Input
            name="number"
            type="text"
            width="100%"
            placeholder="Número do cartão"
            maxLength="19"
            value={maskCreditCard(form.number)}
            onChange={(event) => {
              setForm({ ...form, number: event.target.value });
            }}
          />
          <Spacing size="medium" />
          <Input
            name="name"
            type="text"
            width="100%"
            placeholder="Nome no cartão"
            maxLength="25"
            value={form.name}
            onChange={(event) => {
              setForm({ ...form, name: event.target.value });
            }}
          />
          <Spacing size="medium" />
          <Row position="start" width="100%">
            <Column width="46%">
              <Input
                name="validDate"
                type="text"
                width="100%"
                placeholder="Mês / Ano"
                maxLength="5"
                value={maskValidDate(form.validDate)}
                onChange={(event) => {
                  setForm({ ...form, validDate: event.target.value });
                }}
              />
            </Column>
            <Column width="47%">
              <Input
                name="code"
                type="text"
                width="100%"
                placeholder="CVV"
                maxLength="3"
                value={form.code}
                onChange={(event) => {
                  setForm({ ...form, code: event.target.value });
                }}
              />
            </Column>
          </Row>
        </Column>
      </Row>
      <Spacing size="large" />
      <Row position="end">
        <Button text="Realizar Pagamento" appearance="error" width="162px" onClick={onClick} />
      </Row>
    </Card>
  );
}

export default CreditCard;
