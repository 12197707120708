import React from 'react';
import styles from './styles.module.scss';
import Title from '../Title';
import Text from '../Text';
import Spacing from '../Spacing';
import Button from '../Button';

function Feedback({
  image,
  title,
  description,
  buttonText,
  onClickButton,
  underbuttonText,
  onClickUnderbutton,
  underButtonWidth = '140px'
}: any) {
  return (
    <div className={styles.container}>
      <img src={image} alt="" />
      <Spacing size="xx-large" />
      <Title appearance="primary" bolder={true}>
        {title}
      </Title>
      <Spacing size="xxx-small" />
      <Text>{description}</Text>
      <Spacing size="x-large" />
      {buttonText && (
        <Button text={buttonText} width="140px" appearance="primary" onClick={onClickButton} />
      )}
      <Spacing size="medium" />
      {underbuttonText && (
        <Button
          appearance="ghost"
          width={underButtonWidth}
          text={underbuttonText}
          onClick={onClickUnderbutton}
        />
      )}
    </div>
  );
}

export default Feedback;
