import React from 'react';
import { FaRegCopy } from 'react-icons/fa';
import { Spacing, Text, Card, Column, Description, Undertitle, Row, Button } from '..';
import codebar from '../../assets/codebar.png';
import styles from './styles.module.scss';

function Billet() {
  return (
    <Card appearance="ghost" width="100%">
      <Column position="start">
        <Undertitle bolder={true} appearance="grey">
          Boleto
        </Undertitle>
        <Spacing size="xxx-small" />
        <Description appearance="grey">Será aprovado em 1 a 2 dias úteis</Description>
      </Column>
      <Spacing size="xx-large" />
      <Row>
        <Column>
          <Description textAlign="left">
            1. copie a linha digitável ou escaneie o código de barras
          </Description>
          <Spacing size="xx-small" />
          <Description>2. Para pagar em qualquer banco, imprima o boleto</Description>
          <Spacing size="xx-small" />
          <Description>3. Pagamento aprovado em 1 ou 2dias uteis</Description>
          <Spacing size="medium" />
          <Description appearance="grey">ou copie e cole este código</Description>
          <Spacing size="xx-small" />
          <Card appearance="ghost" width="100%">
            <div className={styles.copyCode}>
              <Text>00000000000000000000000</Text>
              <Text appearance="error" bolder={true} pointer={true}>
                Copiar <FaRegCopy className={styles.icon} />
              </Text>
            </div>
          </Card>
        </Column>
        <Column position="centered">
          <Spacing size="medium" />
          <img src={codebar} alt="codebar" />
        </Column>
      </Row>
      <Spacing size="large" />
      <Row position="end">
        <Button
          text="Gerar Boleto"
          appearance="error"
          width="117px"
          onClick={() => console.log('generate billet')}
        />
      </Row>
    </Card>
  );
}

export default Billet;
