import React from 'react';
import { FaLock } from 'react-icons/fa';
import styles from './styles.module.scss';

function Input({
  name,
  type = 'text',
  placeholder,
  value,
  width = '100%',
  max,
  disabled = false,
  icon,
  position = 'start',
  maxLength,
  onChange,
  onBlur
}: any) {
  return (
    <div className={styles.field} style={{ maxWidth: width && width }}>
      <input
        type={type}
        name={name}
        value={value}
        max={max}
        autoComplete="off"
        disabled={disabled}
        maxLength={maxLength}
        onChange={onChange}
        onBlur={onBlur}
        className={`
          ${value ? styles.labelValueOn : ''} 
          ${disabled ? styles.disabled : ''}
          ${position === 'start' ? styles.start : ''}
          ${position === 'center' ? styles.center : ''}
          ${position === 'end' ? styles.end : ''}
        `}
      />
      <label htmlFor={name} className={`${value ? styles.labelValueOn : ''}`}>
        {placeholder}
      </label>
      {icon && <div className={styles.icon}>{icon}</div>}
      {disabled && (
        <div className={styles.icon}>
          <FaLock />
        </div>
      )}
    </div>
  );
}

export default Input;
