import React from 'react';
import { buttonProps } from '../../types';
import styles from './styles.module.scss';

function Button({
  size = 'md',
  width = '300px',
  appearance = 'primary',
  text = 'I`m a button!',
  children,
  iconPosition = 'right',
  onClick,
  inNavBar = false
}: buttonProps) {
  return (
    <div
      className={`
      ${styles.container} 
      ${size === 'sm' && styles.sm} 
      ${size === 'md' && styles.md} 
      ${size === 'lg' && styles.lg} 
      ${appearance === 'primary' && styles.primary}
      ${appearance === 'complementary' && styles.complementary}
      ${appearance === 'ghost' && styles.ghost}
      ${appearance === 'disabled' && styles.disabled}
      ${appearance === 'success' && styles.success}
      ${appearance === 'warning' && styles.warning}
      ${appearance === 'error' && styles.error}
      ${inNavBar && styles.navBar}
      `}
      style={{
        width: width && width
      }}
      onClick={onClick}
    >
      {iconPosition === 'left' ? children : ''}
      <p>{text}</p>
      {iconPosition === 'right' ? children : ''}
    </div>
  );
}

export default Button;
