import {
  Button,
  Column,
  Input,
  Navbar,
  Row,
  Select,
  Spacing,
  Title,
  Text,
  Description
} from 'components';
import React, { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { api } from 'api/services';
import { getActiveExams, getPackagesByRegions, getPackagesCategories } from 'api/services/ApiCalls';
import target from '../../assets/icons/location-target-icon.png';
import cart from '../../assets/icons/cart-icon.png';
import banner from '../../assets/banner-mdd.png';
import package1 from '../../assets/package-1.png';
import package2 from '../../assets/package-2.png';
import package3 from '../../assets/package-3.png';
import logo from '../../assets/logos/logo-branca.png';
import serviceInsurance from '../../assets/insurance.png';
import serviceMentalHealth from '../../assets/mental-health.png';
import serviceStethoscope from '../../assets/stethoscope.png';
import serviceHeartRate from '../../assets/telemedicina-1.png';
import serviceVaccine from '../../assets/vacina.png';
import serviceVirus from '../../assets/virus.png';
import serviceDNA from '../../assets/dna.png';
import serviceHeart from '../../assets/heart.png';

import styles from './styles.module.scss';
import FAQ from './components/FAQ';

export default function Home() {
  const navigate = useNavigate();
  const [search, setSearch] = useState();
  const [city, setCity] = useState('Porto Alegre');
  const [location, setLocation] = useState({
    city: '',
    county: ''
  });
  const [categories, setCategories] = useState([
    {
      id: 1,
      image: package1,
      name: 'Check-up',
      description:
        ' Disponibilizamos em nossos pacotes de check-up um conjunto de exames e consultas de acordo com sua necessidade e por um valor acessível',
      navigate: ''
    },
    {
      id: 2,
      image: package2,
      name: 'Saúde Atleta',
      description:
        'Um conjunto de procedimentos para atender ao público interessado em começar a fazer atividades físicas, prática de esportes ou competições.',
      navigate: ''
    },
    {
      id: 3,
      image: package3,
      name: 'Saúde Mental',
      description:
        'Acompanhamento psicológico a médio e longo prazo, terapias humanizadas e com todo o respeito que o paciente merece. Pacotes com preços acessíveis.',
      navigate: ''
    }
  ]);

  const FAQMocked = [
    {
      title: 'O que são os Pacotes de Serviço de Saúde da Mãe de Deus?',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed consectetur dolor. In ex nibh, lacinia in rhoncus vel, pulvinar id ante. Quisque nulla tortor, luctus vitae tincidunt sed, semper id justo. Proin facilisis neque felis, sit amet tristique orci ornare et. Sed eu pellentesque lacus. Aliquam eu rutrum odio, ac euismod mi. Sed quis arcu a est luctus auctor. Aliquam vitae lacus non est feugiat venenatis.'
    },
    {
      title: 'Onde são realizados os procedimentos?',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed consectetur dolor. In ex nibh, lacinia in rhoncus vel, pulvinar id ante. Quisque nulla tortor, luctus vitae tincidunt sed, semper id justo. Proin facilisis neque felis, sit amet tristique orci ornare et. Sed eu pellentesque lacus. Aliquam eu rutrum odio, ac euismod mi. Sed quis arcu a est luctus auctor. Aliquam vitae lacus non est feugiat venenatis.'
    },
    {
      title: 'Os pacotes tem prazo de validade?',
      description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam sed consectetur dolor. In ex nibh, lacinia in rhoncus vel, pulvinar id ante. Quisque nulla tortor, luctus vitae tincidunt sed, semper id justo. Proin facilisis neque felis, sit amet tristique orci ornare et. Sed eu pellentesque lacus. Aliquam eu rutrum odio, ac euismod mi. Sed quis arcu a est luctus auctor. Aliquam vitae lacus non est feugiat venenatis.'
    }
  ];

  async function getPackages() {
    try {
      const response = await getPackagesCategories();
      const { categories } = response.data;
      setCategories(categories);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getPackages();
  }, []);

  const locationData = [
    {
      title: 'Porto Alegre',
      value: 'Porto Alegre'
    },
    {
      title: 'São Leopoldo',
      value: 'São Leopoldo'
    },
    {
      title: 'Canoas',
      value: 'Canoa'
    },
    {
      title: 'Novo Hamburgo',
      value: 'Novo Hamburgo'
    }
  ];

  return (
    <div className={styles.container}>
      <Navbar navigationButtons />
      <Spacing size="xxx-large" />
      <div className={styles.searchField}>
        <Column>
          <div className={styles.location}>
            <img src={target} draggable="false" alt="target" />
            <Select
              name="city"
              label=""
              width="25%"
              appearance="ghost"
              data={locationData}
              value={city}
              onclick={(data) => setCity(data)}
            />
          </div>
        </Column>
        <Column position="center">
          <Input
            name="search"
            type="text"
            width="100%"
            icon={<FaSearch />}
            placeholder="O que você precisa hoje?"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </Column>
        <div className={styles.icons}>
          <Row>
            <div className={styles.cart}>
              <img src={cart} className={styles.icon} alt="cart" />
              <div className={styles.notification}>2</div>
            </div>
          </Row>
        </div>
      </div>

      <div className={styles.banner}>
        <img src={banner} className={styles.bannerBackground} draggable="false" alt="banner" />
        <div className={styles.bannerContent}>
          <Title appearance="primary" bolder={true}>
            Conectamos você aos melhores serviços de saúde e bem estar do mercado.
          </Title>
          <Spacing size="xxx-large" />
          <Button text="Planos e Serviços" width="140px" />
        </div>
      </div>

      <div className={styles.packages}>
        <div className={styles.packagesTitle}>
          <Title bolder={true} appearance="primary">
            Nossos serviços para sua saúde são feitos sob medida
          </Title>
        </div>
        <Spacing size="xxx-large" />
        <div className={styles.packagesRow}>
          {categories.map((packager) => (
            <div className={styles.package} key={packager.id}>
              <div className={styles.packageBanner}>
                <img
                  src={packager.image}
                  draggable="false"
                  className={styles.packageBannerImage}
                  alt="packageImage"
                />
              </div>
              <div className={styles.packageContent}>
                <Spacing size="x-large" />
                <Title bolder={true} appearance="primary">
                  {packager.name}
                </Title>
                <Spacing size="medium" />
                <Text appearance="grey">{packager.description}</Text>
              </div>
              <Spacing size="xxx-large" />
              <div className={styles.packageFooter}>
                <Row>
                  <Button
                    text="Planos e Serviços"
                    width="140px"
                    onClick={() => navigate('/product')}
                  />
                </Row>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.services}>
        <Title bolder={true} appearance="primary">
          Serviços e procedimentos
        </Title>
        <Spacing size="medium" />
        <Text>
          Trazemos um novo olhar para sua saúde. Soluções rápidas e eficientes, mais saúde,
          resolutividade e menos gastos para todos.
        </Text>
        <Spacing size="large" />
        <Spacing size="medium" />
        <div className={styles.servicesRow}>
          <div className={styles.service}>
            <img src={serviceVaccine} draggable="false" alt="vacina" />
            <Spacing size="small" />
            <Text bolder={true} appearance="primary">
              Vacinas
            </Text>
            <Spacing size="x-small" />
            <Description>Vacinas de diversas modalidades para adultos e crianças.</Description>
          </div>
          <div className={styles.service}>
            <img src={serviceDNA} draggable="false" alt="vacina" />
            <Spacing size="small" />
            <Text bolder={true} appearance="primary">
              Testes Genéticos
            </Text>
            <Spacing size="x-small" />
            <Description>
              Avaliações e procedimentos a seu favor para prevenção de doenças genéticas
              hereditárias.
            </Description>
          </div>
          <div className={styles.service}>
            <img src={serviceVirus} draggable="false" alt="vacina" />
            <Spacing size="small" />
            <Text bolder={true} appearance="primary">
              Covid
            </Text>
            <Spacing size="x-small" />
            <Description>Procedimentos e cuidados de prevenção e Pós Covid.</Description>
          </div>
          <div className={styles.service}>
            <img src={serviceStethoscope} draggable="false" alt="vacina" />
            <Spacing size="small" />
            <Text bolder={true} appearance="primary">
              Acompanhamento médico
            </Text>
            <Spacing size="x-small" />
            <Description>
              Tenha um profissional especializado para acompanhar a sua saúde.
            </Description>
          </div>
        </div>
        <Spacing size="xx-large" />
        <div className={styles.servicesRow}>
          <div className={styles.service}>
            <img src={serviceHeart} alt="vacina" />
            <Spacing size="small" />
            <Text bolder={true} appearance="primary">
              Exames
            </Text>
            <Spacing size="x-small" />
            <Description>
              Exames em clínicas e laboratórios a preços acessíveis e diversas modalidades
            </Description>
          </div>
          <div className={styles.service}>
            <img src={serviceHeartRate} alt="vacina" />
            <Spacing size="small" />
            <Text bolder={true} appearance="primary">
              Telemedicina
            </Text>
            <Spacing size="x-small" />
            <Description>
              Atendimento médico sem sair de casa para diagnósticos e análise de resultados.
            </Description>
          </div>
          <div className={styles.service}>
            <img src={serviceMentalHealth} alt="vacina" />
            <Spacing size="small" />
            <Text bolder={true} appearance="primary">
              Saúde Mental
            </Text>
            <Spacing size="x-small" />
            <Description>
              Consultas psicológicas e psiquiátricas para cuidar de sua saúde mental.
            </Description>
          </div>
          <div className={styles.service}>
            <img src={serviceInsurance} alt="vacina" />
            <Spacing size="small" />
            <Text bolder={true} appearance="primary">
              Check-up
            </Text>
            <Spacing size="x-small" />
            <Description>
              Realize uma série de exames e procedimentos médicos especializados para prevenção e
              cuidado com a saúde.
            </Description>
          </div>
        </div>
      </div>

      <div className={styles.faq}>
        <Title bolder={true} appearance="primary">
          FAQ
        </Title>
        <Spacing size="medium" />
        <Text appearance="primary">Tire suas dúvidas sobre como funciona nossos serviços</Text>
        <Spacing size="large" />
        {FAQMocked.map((elem) => (
          <FAQ title={elem.title} description={elem.description} key={elem.title} />
        ))}
      </div>

      <div className={styles.footer}>
        <div className={styles.footerContent}>
          <div className={`${styles.footerColumn} ${styles.centered}`}>
            <img src={logo} className={styles.logo} draggable="false" alt="logo" />
            <Description appearance="white">
              Trazemos um novo olhar para sua saúde. Soluções rápidas e eficientes, mais saúde,
              resolutividade e menos gastos para todos.
            </Description>
          </div>
          <div className={`${styles.footerColumn} ${styles.end}`}>
            <div>
              <p>Planos e Serviços</p>
              <Spacing size="medium" />
              <p>Procedimentos</p>
              <Spacing size="medium" />
              <p>Contato</p>
              <Spacing size="medium" />
              <p>Perguntas Frequentes</p>
            </div>
          </div>
          <div className={`${styles.footerColumn} ${styles.end}`}>
            <div>
              <p>Planos e Serviços</p>
              <Spacing size="medium" />
              <p>Procedimentos</p>
              <Spacing size="medium" />
              <p>Contato</p>
              <Spacing size="medium" />
              <p>Perguntas Frequentes</p>
            </div>
          </div>
        </div>
        <Spacing size="xx-large" />
        <Row>
          <Description appearance="white">
            Todos os direitos reservados - 2023. Desenvolvido por Starta Ideia
          </Description>
        </Row>
      </div>
    </div>
  );
}
