import React from 'react';
import { FaRegCopy } from 'react-icons/fa';
import { Card, Column, Description, Row, Text, Undertitle, Spacing } from '..';
import qrcode from '../../assets/qrcode.png';
import styles from './styles.module.scss';

function Pix() {
  return (
    <Card appearance="ghost" width="100%">
      <Column position="start">
        <Undertitle bolder={true} appearance="grey">
          PIX
        </Undertitle>
        <Spacing size="xxx-small" />
        <Description appearance="grey">Escaneie este código para pagar</Description>
      </Column>
      <Spacing size="xx-large" />
      <Row>
        <Column>
          <Description textAlign="left">
            1. Acesse o seu Internet Banking ou app de pagamentos
          </Description>
          <Spacing size="xx-small" />
          <Description>2. Escolha pagar via pix</Description>
          <Spacing size="xx-small" />
          <Description>3. Escaneie o QR Code</Description>
          <Spacing size="medium" />
          <Description appearance="grey">ou copie e cole este código</Description>
          <Spacing size="xx-small" />
          <Card appearance="ghost" width="100%">
            <div className={styles.copyCode}>
              <Text>00000000000000000000000</Text>
              <Text appearance="error" bolder={true} pointer={true}>
                Copiar <FaRegCopy className={styles.icon} />
              </Text>
            </div>
          </Card>
        </Column>
        <Column position="centered">
          <Spacing size="medium" />
          <div className={styles.qrCode}>
            <img src={qrcode} alt="qrcode" />
          </div>
        </Column>
      </Row>
    </Card>
  );
}

export default Pix;
