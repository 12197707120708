import React, { useEffect } from 'react';
import { FaCheck } from 'react-icons/fa';
import styles from './styles.module.scss';

export type Step = {
  title: string;
  status: 'concluded' | 'inProgress' | 'pending';
};

type Props = {
  steps: Step[];
};

const statusStyles = {
  concluded: styles.concluded,
  inProgress: styles.inProgress,
  pending: styles.pending
};

function Steps({ steps }: Props) {
  // const arrayRightAdjust = ['Pagamento', 'Planos', 'Resumo'];

  function styleConcluded(iStatus: string) {
    if (iStatus === 'concluded') {
      return styles.concludedLine;
    }
    return '';
  }

  useEffect(() => {
    const root = document.documentElement;
    // root?.style.setProperty(
    //   "--lineWidth",
    //   steps.length > 3 ? "142px" : "285px"
    // );
    root?.style.setProperty('--width', steps.length > 3 ? '640px' : '612px');
  }, [steps.length]);

  return (
    <div className={styles.containerWrapper}>
      <div className={styles.containerSteps}>
        <div className={styles.dataContainer}>
          <div className={styles.stepperContainer}>
            {steps.map(({ title: stepTitle, status }, index) => (
              <div key={index} style={{ width: '150px' }}>
                <div className={statusStyles[status]} style={{ margin: '0 auto' }}>
                  {status === 'concluded' && <FaCheck size={10} className={styles.stepCheck} />}

                  {/* Line */}
                  {index !== steps.length - 1 ? (
                    <div
                      key={`${index}-l`}
                      className={`${styles.line} ${styleConcluded(status)}`}
                    />
                  ) : (
                    <div key={`${index}-l`} className={`${styles.lineEnding}`} />
                  )}
                </div>
                <p
                  key={index}
                  className={status === 'inProgress' ? styles.titleBold : ''}
                  style={{
                    // paddingRight: arrayRightAdjust.includes(stepTitle) ? 12 : 0,
                    paddingTop: '5px',
                    textAlign: 'center'
                  }}
                >
                  {stepTitle}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Steps;
