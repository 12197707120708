import React, { useState } from 'react';
import { Button, Description, Spacing, Undertitle } from 'components';
import { FaRegTrashAlt, FaRegHeart } from 'react-icons/fa';
import { maskMoney } from 'utils/mask';
// import heart from '../../../../assets/icons/heart-icon-colored.png';
import Amount from '../Amount';
import styles from './styles.module.scss';

function Product({
  image,
  title,
  description,
  productValue,
  totalValue,
  onClickDelete,
  addProduct,
  subsProduct
}) {
  const [amount, setAmount] = useState(1);
  const [value, setValue] = useState<any>(totalValue);
  const [favorite, setFavorite] = useState(false);

  function addAmount() {
    if (amount < 13) {
      const calc = totalValue + productValue;
      setValue(calc);
      setAmount(amount + 1);
      addProduct(calc);
    }
  }

  function subsAmount() {
    if (amount > 1) {
      const calc = totalValue - productValue;
      setValue(calc);
      setAmount(amount - 1);
      subsProduct(calc);
    }
  }

  return (
    <div className={styles.container}>
      <img src={image} alt={title} />
      <div className={styles.column}>
        <Undertitle bolder={true}>{title}</Undertitle>
        <Spacing size="xx-small" />
        <Description textAlign="start">{description}</Description>
        <Spacing size="xx-small" />
        <div className={styles.buttons}>
          <Button
            text="Excluir"
            width="90px"
            appearance="ghost"
            iconPosition="left"
            onClick={onClickDelete}
          >
            <FaRegTrashAlt />
          </Button>
          <Spacing size="medium" />
        </div>
      </div>
      <div className={styles.value}>
        <Undertitle bolder={true} appearance="primary">
          R${maskMoney(value)}
        </Undertitle>
        <Spacing size="xx-small" />
        <Amount amount={amount} addAmount={addAmount} subsAmount={subsAmount} />
      </div>
    </div>
  );
}

export default Product;
