import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Layout,
  Spacing,
  Title,
  Text,
  Card,
  Column,
  Description,
  Undertitle,
  CreditCard,
  Pix,
  Billet
} from 'components';

function Payment() {
  const navigate = useNavigate();
  const [form, setForm] = useState({
    number: '',
    name: '',
    validDate: '',
    code: ''
  });

  return (
    <Layout navbar={true}>
      <Spacing size="xxx-large" />
      <Title appearance="primary" bolder={true}>
        Pagamento
      </Title>
      <Spacing size="x-small" />
      <Text>Selecione a forma de pagamento para concluir a adesão ao plano empresarial.</Text>
      <Spacing size="xxx-large" />
      <Card appearance="primary" width="500px" padding="8px">
        <Column position="centered">
          <Description appearance="grey">Valor do Pagamento</Description>
          <Spacing size="xx-small" />
          <Undertitle bolder={true} appearance="primary">
            R$ 3.004,74
          </Undertitle>
        </Column>
      </Card>
      <Spacing size="large" />
      <CreditCard form={form} setForm={setForm} onClick={() => navigate('/pagamento/aguardando')} />
      <Spacing size="large" />
      <Pix />
      <Spacing size="large" />
      <Billet />
      <Spacing size="xxx-large" />
    </Layout>
  );
}

export default Payment;
