import { Spacing, Text, Title } from 'components';
import React, { useState } from 'react';
import plus from '../../../../assets/icons/plus.png';
import styles from './styles.module.scss';

function FAQ({ title, description, onClick }: any) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Spacing size="xx-small" />
      <div className={styles.question}>
        <div className={styles.questionHeader}>
          <img src={plus} onClick={() => setIsOpen(!isOpen)} alt="plus" />
          <Text bolder aappearance="primary">
            {title}
          </Text>
        </div>
        {isOpen && (
          <div className={styles.questionContent}>
            <Spacing size="xxx-small" />
            <Text position="start" appearance="primary">
              {description}
            </Text>
          </div>
        )}
      </div>
    </>
  );
}

export default FAQ;
