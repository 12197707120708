import React from 'react';
import { BsXLg } from 'react-icons/bs';
import styles from './styles.module.scss';
import { Title, Description, Spacing, Button } from '..';

function Modal({
  isOpen = false,
  width = '500px',
  height,
  image,
  title,
  description,
  children,
  onClickClose,
  footerButtonFirst,
  onClickFooterFirst,
  footerButtonSecond,
  onClickFooterSecond
}: any) {
  return (
    <div>
      {isOpen && (
        <div className={styles.container}>
          <div className={styles.modal} style={{ width: width && width, height: height && height }}>
            <div className={styles.header}>
              {image && <img src={image} alt={title} className={styles.image} />}
              <Title bolder={true} appearance="primary">
                {title}
              </Title>
              <Spacing size="xx-small" />
              <div className={styles.description}>
                <Description>{description}</Description>
              </div>
              <BsXLg className={styles.close} onClick={onClickClose} />
            </div>
            <Spacing size="xxx-large" />
            <div className={styles.body}>{children}</div>
            <div className={styles.footer}>
              <Spacing size="large" />
              {footerButtonFirst && (
                <>
                  <Button
                    appearance="primary"
                    text={footerButtonFirst}
                    size="lg"
                    onClick={onClickFooterFirst}
                  />
                  <Spacing size="small" />
                </>
              )}
              {footerButtonSecond && (
                <>
                  <Button
                    appearance="ghost"
                    text={footerButtonSecond}
                    onClick={onClickFooterSecond}
                  />
                  <Spacing size="xx-small" />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Modal;
