import React from 'react';
import { Row } from '..';
import styles from './styles.module.scss';

function Checkbox({ label, checked, onChange }: any) {
  return (
    <Row position="start">
      <input
        type="checkbox"
        id="checkbox"
        name="checkbox"
        value={checked}
        className={styles.input}
        onChange={onChange}
      />
      <label htmlFor="checkbox">{label}</label>
    </Row>
  );
}

export default Checkbox;
