import {
  Button,
  Column,
  Description,
  Input,
  Navbar,
  Row,
  Select,
  Spacing,
  Title,
  Text,
  Undertitle
} from 'components';
import React, { useEffect, useState } from 'react';
import { FaSearch } from 'react-icons/fa';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getPackagesById } from 'api/services/ApiCalls';
import styles from './styles.module.scss';
import target from '../../assets/icons/location-target-icon.png';
import cart from '../../assets/icons/cart-icon.png';
import banner from '../../assets/banner-product.png';
import checkupBasic from '../../assets/checkup-basic.png';
import checkupEssential from '../../assets/checkup-essencial.png';
import checkupComplete from '../../assets/checkup-complete.png';
import logo from '../../assets/logos/logo-branca.png';
import checkupIcon from '../../assets/icons/checkup-icon.png';

export default function Product() {
  const navigate = useNavigate();
  const [search, setSearch] = useState();
  const [city, setCity] = useState('Porto Alegre');
  const [packages, setPackages] = useState([
    {
      image: checkupBasic,
      name: 'Check-up Básico',
      exams: [
        {
          name: 'Consulta Clínica'
        },
        {
          name: 'Exames Laboratoriais'
        }
      ],
      value: '349,99'
    },
    {
      image: checkupComplete,
      name: 'Check-up Essencial',
      exams: [
        {
          name: 'Consulta Clínica'
        },
        {
          name: 'Exames Laboratoriais'
        },
        {
          name: 'Consulta Nutricionista'
        }
      ],
      value: '349,99'
    },
    {
      image: checkupEssential,
      name: 'Check-up Completo',
      exams: [
        {
          name: 'Consulta Clínica'
        },
        {
          name: 'Exames Laboratoriais'
        },
        {
          name: 'ECG - Eletrocardiograma'
        },
        {
          name: 'Teste Ergometrico'
        }
      ],
      value: '349,99'
    }
  ]);
  const [location, setLocation] = useState({
    city: '',
    county: ''
  });

  async function getPackages() {
    const response = await getPackagesById('1');
    const { packages } = response.data;
    setPackages(packages);
  }

  useEffect(() => {
    getPackages();
  }, []);

  const locationData = [
    {
      title: 'Porto Alegre',
      value: 'Porto Alegre'
    },
    {
      title: 'São Leopoldo',
      value: 'São Leopoldo'
    },
    {
      title: 'Canoas',
      value: 'Canoa'
    },
    {
      title: 'Novo Hamburgo',
      value: 'Novo Hamburgo'
    }
  ];

  return (
    <div className={styles.container}>
      <Navbar navigationButtons />
      <Spacing size="xxx-large" />

      <div className={styles.searchField}>
        <Column>
          <div className={styles.location}>
            <img src={target} draggable="false" alt="target" />
            <Select
              name="city"
              label=""
              width="25%"
              appearance="ghost"
              data={locationData}
              value={city}
              onclick={(data) => setCity(data)}
            />
          </div>
        </Column>
        <Column position="center">
          <Input
            name="search"
            type="text"
            width="100%"
            icon={<FaSearch />}
            placeholder="O que você precisa hoje?"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </Column>
        <div className={styles.icons}>
          <Row>
            <div className={styles.cart}>
              <img src={cart} className={styles.icon} alt="cart" />
              <div className={styles.notification}>2</div>
            </div>
          </Row>
        </div>
      </div>

      <div className={styles.banner}>
        <img src={banner} className={styles.bannerBackground} draggable="false" alt="banner" />
        <div className={styles.bannerContent}>
          <Title appearance="white" bolder={true} size="36px">
            <img src={checkupIcon} draggable="false" alt="icon" />
            Check-up
          </Title>
        </div>
      </div>

      <div className={styles.about}>
        <div className={styles.aboutContent}>
          <Title bolder={true} appearance="primary" position="centered" width="100%">
            Check-up
          </Title>
          <Spacing size="medium" />
          <Text position="centered" width="100%">
            A forma mais fácil de se manter com saúde é a prevenção. Disponibilizamos em nossos
            pacotes de check-up um conjunto de exames e consultas de acordo com sua necessidade e
            por um valor acessível. Conheça os nossos pacotes de exames e escolha o que mais se
            encaixa com o seu perfil e necessidades:
          </Text>
        </div>
      </div>

      <div className={styles.packages}>
        <Spacing size="xxx-large" />
        {packages.length > 0 ? (
          <div className={styles.packagesRow}>
            {packages.map((elem) => (
              <div className={styles.package} key={Element.length}>
                <div className={styles.packageBanner}>
                  <img
                    draggable="false"
                    src={elem.image}
                    className={styles.packageBannerImage}
                    alt="banner"
                  />
                </div>
                <div className={styles.packageContent}>
                  <Spacing size="x-large" />
                  <Undertitle bolder={true} appearance="primary">
                    {elem.name}
                  </Undertitle>
                  <Spacing size="large" />
                  <ul className={styles.productDetails}>
                    {elem.exams.map((exam) => (
                      <li key={exam.name}> {exam.name}</li>
                    ))}
                  </ul>
                </div>
                <Spacing size="xxx-large" />
                <Row>
                  <Column position="end">
                    <Undertitle bolder={true} appearance="primary">
                      {`R$${elem.value}`}
                    </Undertitle>
                    <Description>ou 2x no cartão sem juros</Description>
                  </Column>
                </Row>
                <Spacing size="xxx-large" />
                <div>
                  <Row>
                    <Button
                      text="Comprar"
                      width="84px"
                      onClick={() => navigate('/product/detail')}
                    />
                  </Row>
                </div>
                <Spacing size="xxx-large" />
              </div>
            ))}
          </div>
        ) : (
          <div>
            <Text appearance="grey"> Nenhum Pacote foi encontrado </Text>
          </div>
        )}
      </div>

      <div className={styles.footer}>
        <div className={styles.footerContent}>
          <div className={`${styles.footerColumn} ${styles.centered}`}>
            <img src={logo} className={styles.logo} draggable="false" alt="logo" />
            <Description appearance="white">
              Trazemos um novo olhar para sua saúde. Soluções rápidas e eficientes, mais saúde,
              resolutividade e menos gastos para todos.
            </Description>
          </div>
          <div className={`${styles.footerColumn} ${styles.end}`}>
            <div>
              <p>Planos e Serviços</p>
              <Spacing size="medium" />
              <p>Procedimentos</p>
              <Spacing size="medium" />
              <p>Contato</p>
              <Spacing size="medium" />
              <p>Perguntas Frequentes</p>
            </div>
          </div>
          <div className={`${styles.footerColumn} ${styles.end}`}>
            <div>
              <p>Planos e Serviços</p>
              <Spacing size="medium" />
              <p>Procedimentos</p>
              <Spacing size="medium" />
              <p>Contato</p>
              <Spacing size="medium" />
              <p>Perguntas Frequentes</p>
            </div>
          </div>
        </div>
        <Spacing size="xx-large" />
        <Row>
          <Description appearance="white">
            Todos os direitos reservados - 2023. Desenvolvido por Starta Ideia
          </Description>
        </Row>
      </div>
    </div>
  );
}
