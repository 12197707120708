import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Row, Button } from 'components';
import styles from './styles.module.scss';
import sulmedLogo from '../../assets/logos/logo-branca.png';

function Navbar({ navigationButtons = false }) {
  const navigate = useNavigate();

  return (
    <div className={styles.container}>
      <img src={sulmedLogo} alt="Logo" />
      <Row position="end">
        {navigationButtons && (
          <>
            <Button text="Planos e Serviços" inNavBar width="160px" />
            <Button text="Procedimentos" inNavBar width="160px" />
            <Button text="Perguntas Frequentes" inNavBar width="160px" />
          </>
        )}
      </Row>
    </div>
  );
}

export default Navbar;
