import { api } from '.';

export function getPackagesCategories() {
  return api.get('package-categories');
}

export function getPackagesById(id: string) {
  return api.get(`packages/${id}`);
}

export function getActivePackagesById(id: string) {
  return api.get(`packages-id/${id}`);
}

export function getPackagesByRegions(cep: string) {
  return api.get(`packages-by-regions/${cep}`);
}

export function getActiveExams() {
  return api.get('exams');
}

export function getExamsById(id: string) {
  return api.get(`exam/${id}`);
}

export function getAddressByCep(cep: string) {
  return api.get(`address/${cep}`);
}

export function getStates() {
  return api.get('states');
}

export function getCities() {
  return api.get('cities');
}

export function getCitiesByStateId(id: string) {
  return api.get(`cities/${id}`);
}

export function postBillet(body: any) {
  return api.post('payment/create/billet', body);
}

export function postPix(body: any) {
  return api.post('payment/create/pix', body);
}

export function postPurchase(body: any) {
  return api.post('packages/purchase', body);
}
